<div class="preloader"></div>
<ng-container>
    <header id="header" class="page-topbar" #viewport>


        <!-- =========================
    Header Section
============================== -->
        <section id="wd-header" class="dark-header">
            <div class="container-fluid custom-width">
                <div class="row">
                    <div class="col-md-12 col-lg-3 col-xl-3 text-center second-home-main-logo">
                        <a href="#"><img src="assets/img/logo.png" alt="Logo"></a>
                    </div>
                    <div class="col-md-6 col-lg-6 slider-search-section d-none d-lg-block slider">
                        <div class="input-group">
                            <div class="input-group-btn">
                                <button type="button" class="btn btn-secondary wd-slider-search-btn"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    All Categories <i class="fa fa-angle-down" aria-hidden="true"></i>
                                </button>
                                <div class="dropdown-menu wd-dropdown-menu">
                                    <div class="search-category">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <h6 class="search-category-title">Cameras and photos</h6>
                                                <ul>
                                                    <li><a href="#"><i class="fa fa-angle-double-right"
                                                                aria-hidden="true"></i> Camera Electronice</a></li>
                                                    <li><a href="#"><i class="fa fa-angle-double-right"
                                                                aria-hidden="true"></i> Camera Appereances</a></li>
                                                    <li><a href="#"><i class="fa fa-angle-double-right"
                                                                aria-hidden="true"></i> DSLR</a></li>
                                                    <li><a href="#"><i class="fa fa-angle-double-right"
                                                                aria-hidden="true"></i> Video cameras</a></li>
                                                    <li><a href="#"><i class="fa fa-angle-double-right"
                                                                aria-hidden="true"></i> Best Cameras</a></li>
                                                </ul>
                                            </div>
                                            <div class="col-md-6">
                                                <h6 class="search-category-title">Electronics</h6>
                                                <ul>
                                                    <li><a href="#"><i class="fa fa-angle-double-right"
                                                                aria-hidden="true"></i> Laptops</a></li>
                                                    <li><a href="#"><i class="fa fa-angle-double-right"
                                                                aria-hidden="true"></i> Computer Accessories</a></li>
                                                    <li><a href="#"><i class="fa fa-angle-double-right"
                                                                aria-hidden="true"></i> Mobiles</a></li>
                                                    <li><a href="#"><i class="fa fa-angle-double-right"
                                                                aria-hidden="true"></i> Smartwatches</a></li>
                                                    <li><a href="#"><i class="fa fa-angle-double-right"
                                                                aria-hidden="true"></i>Refrigerators</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <input type="text" class="form-control input-search-box"
                                placeholder="Enter your search key ...">
                            <span class="input-group-btn">
                                <button class="btn btn-secondary wd-search-btn" type="button"><i class="fa fa-search"
                                        aria-hidden="true"></i></button>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- =========================
    Header Section
============================== -->
        <section id="wd-header-2" class="wd-header-nav sticker-nav mob-sticky bg-orange">
            <div class="container-fluid custom-width">
                <div class="row">
                    <div class="col-md-8 col-2 col-sm-6 col-md-4 d-block d-lg-none">
                        <div class="accordion-wrapper hide-sm-up">
                            <span class="mobile-open"><i class="fa fa-bars"></i></span>
                            <!--Mobile Menu start-->

                            <ul id="mobilemenu" class="accordion">
                                <!-- <li class="mob-logo"><a href="index.html"><img src="assets/img/logo.png" alt=""></a></li>-->
                                <li><span class="closeme"><i class="fa fa-times"></i></span></li>
                                <li class="mob-logo"><a href="#"><img src="assets/img/logo.png" alt=""></a></li>


                                <li>
                                    <div class="link">Home<i class="fa fa-chevron-down"></i></div>
                                    <ul class="submenu font-sky">

                                    </ul>
                                </li>

                                <li>
                                    <div class="link">Categories<i class="fa fa-chevron-down"></i></div>
                                    <ul class="submenu ">
                                        <li><a href="#">Visual Phones</a></li>
                                        <li><a href="#">Chinese phones</a></li>
                                        <li><a href="#">Google Phones</a></li>
                                        <li><a href="#">Video cameras</a></li>
                                        <li><a href="#">Best Cameras</a></li>
                                        <li><a href="#">Cheap Cameras</a></li>
                                        <li><a href="#">Best Cameras</a></li>
                                        <li><a href="#">Luxury Cameras</a></li>
                                        <li><a href="#">Simple Cameras</a></li>
                                        <li><a href="#">Phone Electronice</a></li>
                                        <li><a href="#">Phone Appereances</a></li>
                                        <li><a href="#">Visual Phones</a></li>
                                        <li><a href="#">Chinese phones</a></li>
                                        <li><a href="#">Google Phones</a></li>
                                        <li><a href="#">Cheap Phones</a></li>
                                        <li><a href="#">Luxury phones</a></li>
                                        <li><a href="#">Simple phones</a></li>
                                        <li><a href="#">Camera Electronice</a></li>
                                        <li><a href="#">Camera Appereances</a></li>
                                        <li><a href="#">DSLR</a></li>
                                        <li><a href="#">Video cameras</a></li>
                                        <li><a href="#">Best Cameras</a></li>
                                        <li><a href="#">Cheap Cameras</a></li>
                                        <li><a href="#">Best Cameras</a></li>
                                        <li><a href="#">Luxury Cameras</a></li>
                                        <li><a href="#">Simple Cameras</a></li>
                                    </ul>

                                </li>

                                <li class="out-link"><a class="" href="blog/all">Blog</a></li>
                                <li class="out-link"><a class="" href="#">Coupon</a></li>
                                <li class="out-link"><a class="" href="#">Contact</a></li>

                            </ul>
                            <!--Mobile Menu end-->
                        </div>
                    </div>
                    <!--Mobile menu end-->
                    <div class="col-xl-3 d-none d-xl-block">
                        <div class="department" id="cat-department">
                            <img src="assets/img/menu-bar.png" alt="menu-bar">
                            All Department
                            <div class="shape-img">
                                <img src="assets/img/department-shape-img.png" class="img-fluid" alt="department img">
                            </div>
                            <div id="department-list" class="department-list" style="display: none;">
                                <ul class="list-group">
                                    <li class="list-group-item">
                                        <a href="#!">
                                            <div class="department-list-logo">
                                                <img src="assets/img/department-img/department-img-5.png" alt="">
                                            </div>
                                            <span class="sub-list-main-menu">Electronics</span> <i
                                                class="fa fa-angle-right" aria-hidden="true"></i>
                                        </a>
                                        <div class="wd-sub-list">
                                            <div class="container">
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <h6 class="black-color wd-sub-list-title">Cameras and photos
                                                        </h6>
                                                        <ul class="wd-sub-menu">
                                                            <li><a href="#">Camera Electronice</a>
                                                            </li>
                                                            <li><a href="#">Camera Appereances</a>
                                                            </li>
                                                            <li><a href="#">DSLR</a></li>
                                                            <li><a href="#">Video cameras</a></li>
                                                            <li><a href="#">Best Cameras</a></li>
                                                            <li><a href="#">Cheap Cameras</a></li>
                                                            <li><a href="#">Best Cameras</a></li>
                                                            <li><a href="#">Luxury Cameras</a></li>
                                                            <li><a href="#">Simple Cameras</a></li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <h6 class="black-color wd-sub-list-title">Laptops by brand
                                                        </h6>
                                                        <ul class="wd-sub-menu">
                                                            <li><a href="#">Apple</a>
                                                            </li>
                                                            <li><a href="#">Lenovo</a>
                                                            </li>
                                                            <li><a href="#">Hp</a></li>
                                                            <li><a href="#">Asus</a></li>
                                                            <li><a href="#">Acer</a></li>
                                                            <li><a href="#">Microsoft</a></li>
                                                            <li><a href="#">LG</a></li>
                                                            <li><a href="#">Infinix</a></li>
                                                            <li><a href="#">Honor</a></li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <h6 class="black-color wd-sub-list-title">Home & Kitchen
                                                            Appliances
                                                        </h6>
                                                        <ul class="wd-sub-menu">
                                                            <li><a href="#">Refrigerators</a>
                                                            </li>
                                                            <li><a href="#">Air Conditioners</a>
                                                            </li>
                                                            <li><a href="#">Microvens</a></li>
                                                            <li><a href="#">Air Fryers</a></li>
                                                            <li><a href="#">Fans</a></li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <img src="assets/img/department-img/department-sub-list-img-1.jpg"
                                                            class="department-sub-list-img"
                                                            alt="department-sub-list-img">
                                                    </div>
                                                    <div class="col-md-6">
                                                        <img src="assets/img/department-img/department-sub-list-img-2.jpg"
                                                            class="department-sub-list-img"
                                                            alt="department-sub-list-img">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="list-group-item"><a href="#">
                                            <div class="department-list-logo">
                                                <img src="assets/img/department-img/department-img-2.png" alt="">
                                            </div><span class="sub-list-main-menu">Household</span>
                                        </a>
                                    </li>
                                    <li class="list-group-item"><a href="#">
                                            <div class="department-list-logo">
                                                <img src="assets/img/department-img/department-img-3.png" alt="">
                                            </div><span class="sub-list-main-menu">Clothes</span>
                                        </a>
                                    </li>
                                    <li class="list-group-item"><a href="#">
                                            <div class="department-list-logo">
                                                <img src="assets/img/department-img/department-img-4.png" alt="">
                                            </div><span class="sub-list-main-menu">Accessories</span>
                                        </a>
                                    </li>
                                    <li class="list-group-item"><a href="#">
                                            <div class="department-list-logo">
                                                <img src="assets/img/department-img/department-img-1.png" alt="">
                                            </div><span class="sub-list-main-menu">Furniture</span>
                                        </a>
                                    </li>
                                    <li class="list-group-item"><a href="#">
                                            <div class="department-list-logo">
                                                <img src="assets/img/department-img/department-img-6.png" alt="">
                                            </div><span class="sub-list-main-menu">Corporate staff</span>
                                        </a>
                                    </li>
                                    <li class="list-group-item"><a href="#">
                                            <div class="department-list-logo">
                                                <img src="assets/img/department-img/department-img-7.png" alt="">
                                            </div><span class="sub-list-main-menu">Sinking staff</span>
                                        </a>
                                    </li>
                                    <li class="list-group-item"><a href="#">
                                            <div class="department-list-logo">
                                                <img src="assets/img/department-img/department-img-8.png" alt="">
                                            </div><span class="sub-list-main-menu">Plant</span>
                                        </a>
                                    </li>
                                    <li class="list-group-item"><a href="#">
                                            <div class="department-list-logo">
                                                <img src="assets/img/department-img/department-img-9.png" alt="">
                                            </div><span class="sub-list-main-menu">Washing machine</span>
                                        </a>
                                    </li>
                                    <li class="list-group-item"><a href="#">
                                            <div class="department-list-logo">
                                                <img src="assets/img/department-img/department-img-10.png" alt="">
                                            </div><span class="sub-list-main-menu">Winding staff</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-10 col-xl-7 header-search-box d-none d-lg-block">
                        <div id="main-menu-2" class="main-menu-desktop no-border main-menu-sh">
                            <div class="menu-container wd-megamenu text-left">
                                <div class="menu">
                                    <ul class="wd-megamenu-ul">
                                        <li><a href="#" class="main-menu-list"><i class="fa fa-home"
                                                    aria-hidden="true"></i> Home <i class="fa fa-angle-down angle-down"
                                                    aria-hidden="true"></i></a>

                                        </li>
                                        <li class="pos-inherit"><a href="#" class="main-menu-list ">best10 <i
                                                    class="fa fa-angle-down angle-down" aria-hidden="true"></i></a>
                                            <ul class="single-dropdown megamenu">
                                                <li><a href="#" class="menu-subtitle">Electronics</a>
                                                    <ul class="sub-menu-list">
                                                        <li><a href="/blog/best-10-laptops-sold-in-amazon-in-2021.html"><i class="fa fa-angle-double-right"
                                                                    aria-hidden="true"></i> Best 10 Laptops</a></li>
                                                        <li><a href="#"><i class="fa fa-angle-double-right"
                                                                    aria-hidden="true"></i>Best 10 Smartwatches</a></li>
                                                        <li><a href="#"><i class="fa fa-angle-double-right"
                                                                    aria-hidden="true"></i>Best 10 Mobiles</a></li>
                                                        <li><a href="#"><i class="fa fa-angle-double-right"
                                                                    aria-hidden="true"></i>Best 10 AC's</a>
                                                        </li>
                                                        <li><a href="#"><i class="fa fa-angle-double-right"
                                                                    aria-hidden="true"></i>Best 10 Cameras</a></li>
                                                        <li><a href="#"><i class="fa fa-angle-double-right"
                                                                    aria-hidden="true"></i>Best 10 Projectors</a></li>


                                                    </ul>
                                                </li>
                                                <li><a href="#" class="menu-subtitle">Home & Kitchen Appliances</a>
                                                    <ul class="sub-menu-list">

                                                        <li><a href="#"><i class="fa fa-angle-double-right"
                                                                    aria-hidden="true"></i>Best 10 Washing machines</a>
                                                        </li>
                                                        <li><a href="#"><i class="fa fa-angle-double-right"
                                                                    aria-hidden="true"></i>Best 10 Washing machines
                                                                under 20000Rs</a></li>
                                                        <li><a href="#"><i class="fa fa-angle-double-right"
                                                                    aria-hidden="true"></i>Best 10 Microvens</a></li>
                                                        <li><a href="#"><i class="fa fa-angle-double-right"
                                                                    aria-hidden="true"></i>Best 10 Refrigerators</a>
                                                        </li>
                                                        <li><a href="#"><i class="fa fa-angle-double-right"
                                                                    aria-hidden="true"></i>Best 10 Refrigerators under
                                                                30000Rs</a></li>
                                                        <li><a href="#"><i class="fa fa-angle-double-right"
                                                                    aria-hidden="true"></i>Best 10 Mixers & Grinders</a>
                                                        </li>
                                                        <li><a href="#"><i class="fa fa-angle-double-right"
                                                                    aria-hidden="true"></i>Best 10 budget kettels</a>
                                                        </li>

                                                    </ul>
                                                </li>
                                                <li><a href="#" class="menu-subtitle">Markets</a>
                                                    <ul class="sub-menu-list">
                                                        <li><a href=""><i class="fa fa-angle-double-right"
                                                                    aria-hidden="true"></i>Best 10 TV's in Flipkart</a>
                                                        </li>
                                                        <li><a href=""><i class="fa fa-angle-double-right"
                                                                    aria-hidden="true"></i>Best 10 TV's in Amazon</a>
                                                        </li>
                                                        <li><a href=""><i class="fa fa-angle-double-right"
                                                                    aria-hidden="true"></i>Best Buy Products in
                                                                Myntra</a></li>
                                                        <li><a href=""><i class="fa fa-angle-double-right"
                                                                    aria-hidden="true"></i>Best Home & Kitchen
                                                                Appliances in Amazon</a></li>
                                                        

                                                    </ul>
                                                </li>
                                                <li>
                                                    <a href="#" class="add-img"><img src="assets/img/nav-img-1.jpg"
                                                            class="figure-img img-fluid" alt="Product Img"></a>
                                                    <a href="#" class="add-img"><img src="assets/img/nav-img-2.jpg"
                                                            class="figure-img img-fluid" alt="Product Img"></a>
                                                </li>
                                            </ul>
                                        </li>
                                        <li><a href="blog/all" class="main-menu-list">blog</a></li>
                                        <li><a href="#" class="main-menu-list">Comparison Product <i
                                                    class="fa fa-angle-down angle-down" aria-hidden="true"></i></a>
                                            <ul class="single-dropdown">
                                                <li><a href="#">Coming soon</a></li>
                                            </ul>
                                        </li>
                                        <li><a href="#" class="main-menu-list">Coupons</a></li>
                                        <li><a href="contact-us.html" class="main-menu-list">Contact</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-10 col-sm-6 col-md-4 col-lg-2 col-xl-2 text-right">
                        <!-- =========================
                         Cart Out 
                    ============================== -->

                    </div>
                </div>
            </div>
        </section>

    </header>
    <!-- END HEADER -->
    <mat-sidenav-container class="example-container 1">
        <mat-sidenav-content>
            <div id="main">
                <!-- <div class="breadcrump-sec">
                    <div class="container-fluid px-0">
                        <app-breadcrumb> </app-breadcrumb>
                    </div>
                </div> -->
                <div class="carousel-c">
                    <div class="container-fluid main-sec">
                        <router-outlet></router-outlet>
                    </div>
                </div>
            </div>
        </mat-sidenav-content>
    </mat-sidenav-container>
</ng-container>
<app-footer></app-footer>