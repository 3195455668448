import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import moment from 'moment';
import * as products from './products.json'
import * as blogs from './blogs.json'


let blogsData: any = blogs
@Injectable()
export class TheB10ProductsService {

    getRecentlyAddedProducts() {
        let resultJson: any = {};
        for (const [key, value] of Object.entries(products)) {
            if (value.data)
                if (value.data.length < 10) {
                    resultJson[key] = value;
                } else {
                    resultJson[key] = { title: value.title, data: value.data.slice(-10) };
                }
        }
        return Object.values(resultJson);
    }

    getProduct(category: string, id: string) {
        let productsJson: any = { ...products }
        if (productsJson[category] && productsJson[category]['data']) {
            return productsJson[category]['data'].find((element: { id: string; }) => element.id == id);
        }
    }

    getBlogsForPagination(category: string, type: string, publishedYear: number, searchByKey: string) {
        let resultArr: any = [];
        if (category) {
            resultArr = blogsData[category]
        } else if (type) {
            for (const [key, value] of Object.entries(blogs)) {
                resultArr = resultArr.concat(value.filter(function checkAdult(element: any) {
                    return element.type == type;
                }));
            }
        } else if (publishedYear) {
            for (const [key, value] of Object.entries(blogs)) {
                resultArr = resultArr.concat(value.filter(function checkAdult(element: any) {
                    return element.publishedDate.split("-")[2] == publishedYear;
                }));
            }
        } else if (searchByKey) {
            for (const [key, value] of Object.entries(blogs)) {
                resultArr = resultArr.concat(value.filter(function checkAdult(element: any) {
                    return element.title.includes(searchByKey) || element.overview.includes(searchByKey)
                }));
            }
        } else {
            for (const [key, value] of Object.entries(blogs)) {
                if (value && value.length > 0) {
                    resultArr = resultArr.concat(value)
                }
            }

        }
        resultArr.forEach((element: any) => {
            let date = moment(element.publishedDate)
            element['pMonth'] = date.format('MMMM')
            element['pDate'] = date.format('MM/YYYY')
        });
        return _.chunk(resultArr, 9);
    }

    getBlogCategories() {
        let categories = Object.keys(blogs)
        categories.splice(-1)
        return categories;
    }
}