import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { BlogsComponent } from '../views/blogs/blogs.component';
import { HomeComponent } from '../views/home/home.component';
import { FullLayoutComponent } from '../views/layout';
import { ProductDetailsComponent } from '../views/product/product-details.component';
import { AppCustomPreloader } from './app-routing-loader';

export const routes: Routes = [
    
    {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
        data: {
            title: ''
        }
    },

    {
        path: '',
        component: FullLayoutComponent,
        data: {
            title: 'Home',
            breadcrumb: 'Home'
        },
        children: [
            {
                path: 'home',
                component: HomeComponent,
                data: {
                    title: 'home',
                    breadcrumb: 'Home',
                    preload: true
                }
            },
            {
                path: 'product',
                component: ProductDetailsComponent,
                data: {
                    title: 'Product Details View',
                    breadcrumb: 'Product Details View',
                    preload: true
                }
            },
            {
                path: 'blog/all',
                component: BlogsComponent,
                data: {
                    title: 'Blogs',
                    breadcrumb: 'Blogs',
                    preload: true
                }
            }


        ]
    }



];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        preloadingStrategy: PreloadAllModules
      })],
    exports: [RouterModule],
    providers: [AppCustomPreloader]
})
export class AppRoutingModule { }


