import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { BreadcrumbModule } from 'src/app/breadcrumb/breabcrumb.module';
import { MaterialModule } from 'src/app/material.module';
import { TheB10ProductsService } from 'src/app/services/theb10.service';
import { SafeHtmlPipe } from '../services/safe-html.pipe';
import { BlogsComponent } from './blogs/blogs.component';
import { FooterComponent } from './footer/footer.component';

import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { FullLayoutComponent } from './layout';
import { ProductDetailsComponent } from './product/product-details.component';
import { ProductDetailsQuickViewComponent } from './product/product-quick-view.model.componet';


@NgModule({
  declarations: [
    HomeComponent,
    FullLayoutComponent,
    HeaderComponent,
    ProductDetailsComponent,
    SafeHtmlPipe,
    ProductDetailsQuickViewComponent,
    FooterComponent,
    BlogsComponent
  ],
  entryComponents: [ProductDetailsQuickViewComponent],
  imports: [
    BrowserModule,
    RouterModule,
    MaterialModule,
    BreadcrumbModule
  ]
})
export class ViewesModule { }
