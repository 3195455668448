import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';
import { TheB10ProductsService } from 'src/app/services/theb10.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit,AfterViewInit {
  
  recentlyAddedProducts:any={};
  
  constructor(public readonly location: Location,
      public fb: FormBuilder,
      public dialog: MatDialog,
      public router: Router,
      public cdr: ChangeDetectorRef,
      public route: ActivatedRoute,
      public theB10ProductsService: TheB10ProductsService,) {

  }
  ngAfterViewInit(): void {
   
  }

  ngOnInit() {
     // this.recentlyAddedProducts = this.theB10ProductsService.getRecentlyAddedProducts();
    
  }

  gotToProductView(product:any){
      this.router.navigate( ['/product'], { queryParams: { category: product.category,id:product.id } })
      //.then(() => {
        //  window.location.reload();
        //})
     // window.open(window.location.protocol + "//" + window.location.host + `/product?category=${product.category}&id=${product.id}`, "_blank");
  }
}
