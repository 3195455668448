import { CommonModule } from '@angular/common';
import { NgModule ,CUSTOM_ELEMENTS_SCHEMA,NO_ERRORS_SCHEMA} from '@angular/core';
import {  BreadcrumbComponent} from './breadcrumb';
import {MaterialModule} from '../material.module'
@NgModule({
    imports: [
        CommonModule,MaterialModule
    ],
    declarations: [
       BreadcrumbComponent
    ],
    exports: [
        BreadcrumbComponent
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA ],

  })
  export class BreadcrumbModule {}
