import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-layout',
  templateUrl: './full-layout.component.html'
})
export class FullLayoutComponent implements OnInit {
  
  public loading = true;
  public disabled = false;
  public status: {isopen: boolean} = {isopen: false};
  public reloadCounter= 0;
  public toggled(open: boolean): void {
    
  }
  public toggleDropdown($event: MouseEvent): void {
    $event.preventDefault();
    $event.stopPropagation();
    this.status.isopen = !this.status.isopen;
  }
  constructor(public location: Location){

  }
  ngOnInit(){

  }
}
