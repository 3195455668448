<div id="product-quick-view-modal" class="product-view-modal modal fade" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content" *ngIf="product">

            <!-- ====================================
            Product Details Gallery Section
        ========================================= -->
            <div class="row">
                <div class="product-gallery col-12 col-md-12 col-lg-6">
                    <!-- ====================================
                    Single Product Gallery Section
                ========================================= -->
                    <div class="row">
                        <div class="col-md-12 product-slier-details">
                            <div id="product-view-model" class="product-view">
                                <div class="item">
                                    <img src="{{product?.images?.[0]}}" 
                                        alt="Product Img">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-12 col-md-12 col-lg-6">
                    <div class="product-details-gallery">
                        <div class="list-group">
                            <h4 class="list-group-item-heading product-title">
                                {{product.brand}} {{product.id}}
                            </h4>
                            <div class="media">
                                <div class="media-left media-middle">
                                    <div class="rating">
                                        <a href="#"><i
                                                class="fa fa-star{{product.overallRating>=1?' active-color':'-o'}}"
                                                aria-hidden="true"></i></a>
                                        <a href="#"><i
                                                class="fa fa-star{{product.overallRating>=2?' active-color':'-o'}}"
                                                aria-hidden="true"></i></a>
                                        <a href="#"><i
                                                class="fa fa-star{{product.overallRating>=3?' active-color':'-o'}}"
                                                aria-hidden="true"></i></a>
                                        <a href="#"><i
                                                class="fa fa-star{{product.overallRating>=4?' active-color':'-o'}}"
                                                aria-hidden="true"></i></a>
                                        <a href="#"><i
                                                class="fa fa-star{{product.overallRating>=5?' active-color':'-o'}}"
                                                aria-hidden="true"></i></a>
                                    </div>
                                </div>
                                <div class="media-body">
                                    <p>{{product.overallRating}}/5 <span class="product-ratings-text">
                                            -{{product.totalNoOfRatings?product.totalNoOfRatings:17233}}
                                            Ratings</span></p>
                                </div>
                            </div>
                        </div>
                        <div class="list-group content-list">
                            <p><i class="fa fa-dot-circle-o" aria-hidden="true"></i> 100% Original product</p>
                            <p><i class="fa fa-dot-circle-o" aria-hidden="true"></i> Manufacturer Warranty</p>
                        </div>
                    </div>
                    <div class="product-store row">
                        <div class="col-12 product-store-box" *ngFor="let market of product.markets">
                            <div class="row">
                                <div class="col-3 p0 store-border-img">
                                    <img src="assets/img/brands/{{market.title}}.webp" style="padding-top: 17px;" class="figure-img img-fluid"
                                        alt="Product Img">
                                </div>
                                <div class="col-5 store-border-price text-center">
                                    <div class="price">
                                        <p>&#x20b9; {{market?.price}}</p>
                                    </div>
                                </div>
                                <div class="col-4 store-border-button" style="padding-bottom:10px">
                                    <a href="{{market.link}}" target="_blank"
                                        class="btn btn-primary wd-shop-btn pull-right">
                                        Buy it now
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>