<!-- =========================
    Call To Action Section
============================== -->
<section id="call-to-action" class="d-flex align-items-center">
    <div class="container ">
        <div class="row ">
            <div class="col-12 col-md-6">
                <h2 class="call-to-action-message">The most happy <span class="bold-font">Comparison</span>  to buy product with great <span class="bold-font">Features.</span></h2>
            </div>
            <div class="col-12 col-md-6 text-right">
                <div class="call-to-action-buy-now">
                    <!-- By Theme -->
                    <a href="#" class="btn btn-primary wd-shop-btn">
                       Go <i class="fa fa-arrow-right" aria-hidden="true"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- =========================
    Details Section
============================== -->
<section id="details">
    <div class="container">
        <div class="row wow fadeInLeft animated justify-content-center" data-wow-delay="600ms">
            <div class="col-10 col-sm-8 col-lg-3 details-box">
                <div class="row">
                    <div class="col-sm-3 p0 text-center">
                        <div class="details-img">
                            <img class="assets/img-fluid main-hover-icon-compare" src="assets/img/details-img/compare-icon.png" alt="compare-icon">
                            <img class="assets/img-fluid hover-icon-compare" src="assets/img/details-img/compare.png" alt="compare-icon">
                        </div>
                    </div>
                    <div class="col-sm-9 p0 details-description">
                        <h6 class="details-title">Lets Compare</h6>
                        <p>Choose your product with price comparisons make your best deal today</p>
                    </div>
                </div>
                <div class="arow">
                    <img src="assets/img/details-img/angle2.png" alt="">
                </div>
            </div>
            <div class="col-10 col-sm-8 col-lg-3 details-box">
                <div class="row">
                    <div class="col-sm-3 p0 text-center">
                        <div class="details-img">
                            <img class="assets/img-fluid main-hover-icon-user" src="assets/img/details-img/review-icon.png" alt="review-icon">
                            <img class="assets/img-fluid hover-icon-user" src="assets/img/details-img/user-2.png" alt="review-icon">
                        </div>
                    </div>
                    <div class="col-sm-9 p0 details-description">
                        <h6 class="details-title">Take Review</h6>
                        <p>Take your selected product review choose best one</p>
                    </div>
                </div>
                <div class="arow">
                    <img src="assets/img/details-img/angle2.png" alt="">
                </div>
            </div>
            <div class="col-10 col-sm-8 col-lg-3 details-box">
                <div class="row">
                    <div class="col-sm-3 p0 text-center">
                        <div class="details-img">
                            <img class="assets/img-fluid main-hover-icon-vendor" src="assets/img/details-img/shop.png" alt="vendor-icon">
                            <img class="assets/img-fluid hover-icon-vendor" src="assets/img/details-img/vendor-icon.png" alt="vendor-icon">
                        </div>
                    </div>
                    <div class="col-sm-9 p0 details-description">
                        <h6 class="details-title">Choose Multi-Vendor Store</h6>
                        <p>Lets check your product from multivendor store get satisfy.</p>
                    </div>
                </div>
                <div class="arow">
                    <img src="assets/img/details-img/angle2.png" alt="">
                </div>
            </div>
            <div class="col-10 col-sm-8 col-lg-3 details-box details-active">
                <div class="text-center">
                    <img class="assets/img-fluid" src="assets/img/details-img/gift-icon.png" alt="gift-icon">
                    <h3 class="details-active-title">Enjoy Result</h3>
                </div>
            </div>
        </div>
    </div>
</section>

<footer class="footer wow fadeInUp animated footer-3" data-wow-delay="900ms">
    <div class="container-fluid custom-width">
        <div class="row">
            <div class="col-md-12 col-lg-2">
                <!-- ===========================
                        Footer About
                     =========================== -->
                <div class="footer-about">
                    <a href="index.html" class="footer-about-logo">
                        <img src="assets/img/logo.png" alt="Logo">
                    </a>
                    <div class="footer-description">
                        <p>Quality is not an act, it is a habit. And these 10 products embody that habit, consistently delivering excellence to their users - thebest10products.com.</p>
                    </div>
                    <div class="wb-social-media">
                    <a href="#" class="bh"><i class="fa fa-behance"></i></a>
                    <a href="#" class="fb"><i class="fa fa-facebook-official"></i></a>
                    <a href="#" class="db"><i class="fa fa-dribbble"></i></a>
                    <a href="#" class="gp"><i class="fa fa-google-plus"></i></a>
                    <a href="#" class="vn"><i class="fa fa-vine"></i></a>
                    <a href="#" class="yt"><i class="fa fa-youtube-play"></i></a>
                </div>
                </div>
            </div>
            <div class="col-6 col-md-3 col-lg-2 footer-nav">
                <!-- ===========================
                        Festival Deals
                     =========================== -->
                <h6 class="footer-subtitle">Festival Deals</h6>
                <ul>
                    <li><a href="#"> Home </a></li>
                    <li><a href="#">Comparison Product </a></li>
                    <li><a href="#">Reviews</a></li>
                    <li><a href="#">Blog</a></li>
                </ul>
            </div>
            <div class="col-6 col-md-3 col-lg-2 footer-nav">
                <!-- ===========================
                        Top Stores
                     =========================== -->
                <div class="stores-list">
                    <h6 class="footer-subtitle">Top Stores</h6>
                    <ul>
                        <li><a href="#">Amazon</a></li>
                        <li><a href="#">Flipkart</a></li>
                        <li><a href="#">Croma</a></li>
                        <li><a href="#">TataCliq</a></li>
                        <li><a href="#">Myntra</a></li>
                        
                    </ul>
                </div>
            </div>
            <div class="col-6 col-md-3 col-lg-2 footer-nav">
                <!-- ===========================
                        Need Help ?
                     =========================== -->
                <h6 class="footer-subtitle">Need Help ?</h6>
                <ul>
                    <li><a href="#">Getting Started</a></li>
                    <li><a href="#">Contact Us</a></li>
                    <li><a href="#">FAQ's</a></li>
                    <li><a href="#">Press</a></li>
                    <li><a href="#">Product Feed</a></li>
                    <li><a href="#">Best Rated Product</a></li>
                    <li><a href="#">Feature product</a></li>
                </ul>
            </div>
            <div class="col-6 col-md-3 col-lg-2 footer-nav">
                <!-- ===========================
                        About
                     =========================== -->
                <h6 class="footer-subtitle">About</h6>
                    <ul>
                        <li><a href="#">Privacy</a></li>
                        <li><a href="#">Return Policy</a></li>
                    </ul>
            </div>
            <div class="col-12 col-md-12 col-lg-2">
                <h6 class="footer-subtitle">Newsletter Signup</h6>
                <p class="newsletter-content">By subscribing to our mailing list you will always be update with the latest news from us.</p>
                <div class="newsletter-form">
                    <form>
                        <div class="form-group">
                            <input type="text" class="form-control newsletter-input" placeholder="Enter your email">
                        </div>
                        <button type="submit" class="btn btn-primary newsletter-btn">Join us</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- =========================
    CopyRight
============================== -->
<section class="copyright wow fadeInUp animated copyright-2" data-wow-delay="900ms">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="copyright-text">
                    <p class="text-uppercase">COPYRIGHT &copy; 2023</p><a class="created-by" href="https://thebest10products.com/">thebest10products.com</a>
                </div>
            </div>
        </div>
    </div>
</section>

