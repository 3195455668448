import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TheB10ProductsService } from 'src/app/services/theb10.service';
declare var $:any;

@Component({
    selector: 'product-details',
    templateUrl: './product-details.component.html'
})
export class ProductDetailsComponent implements OnInit,AfterViewInit {

    category: any;
    id: any
    product: any
    specKeys: any
    lightSlider: any
    showData = false
    constructor(public readonly location: Location,
        public fb: FormBuilder,
        public dialog: MatDialog,
        public router: Router,
        public cdr: ChangeDetectorRef,
        public route: ActivatedRoute,
        public theB10ProductsService: TheB10ProductsService,) {
        this.route.queryParams.subscribe(params => {
                this.category = params['category'];
                this.id = params['id'];
                this.product = this.theB10ProductsService.getProduct(this.category, this.id);
                this.specKeys = Object.keys(this.product.spec);
                this.buildLightSlider()
                this.showData = true
         });
    }

    ngOnInit() {
       
        if (!this.product) {

        }
    }

    ngAfterViewInit(): void {
        $('#lightSlider').lightSlider({
            gallery: true,
            item: 1,
            loop: true,
            slideMargin: 0,
            thumbItem: 5
        });
    }
    
    buildLightSlider() {
        let sliderHtml = `<ul id="lightSlider">`;
        for (let imgLink of this.product.images) {
            sliderHtml = sliderHtml + ` <li data-thumb="${imgLink}">
             <img class="figure-img img-fluid" src="${imgLink}" alt="product-img" />
         </li>`
        }
        sliderHtml = sliderHtml + "</ul>";
        this.lightSlider = sliderHtml
    }
}
