<div #viewport>

    <section id="main-slider-section">
        <div id="main-slider" class="slider-bg2  owl-carousel owl-theme product-review slider-cat">
            <div class="item d-flex  slider-bg align-items-center">
                <div class="container-fluid">
                    <div class="row justify-content-end">
                        <div class="slider-text col-sm-6  col-xl-4   col-md-6 order-2 order-sm-1">
                            <h1 class="slider-title"><strong class="highlights-text">Join</strong> Telegram</h1>
                            <p class="slider-content">For Top Deals.</p>
                            <a href="https://t.me/thebest10products" class="btn btn-primary wd-shop-btn slider-btn">
                                Click Here for join<i class="fa fa-arrow-right" aria-hidden="true"></i>
                            </a>
                        </div>
                        <div class="col-sm-6  col-md-6 col-xl-6 order-1 order-sm-2 slider-img">
                            <img src="assets/img/telegram-qr.png" style="width: 50%;" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <div class="item d-flex  slider-bg align-items-center">
                <div class="container-fluid">
                    <div class="row justify-content-end">
                        <div class="slider-text col-sm-6  col-xl-4   col-md-6 order-2 order-sm-1">
                            <h1 class="slider-title"><strong class="highlights-text">Join</strong> Telegram</h1>
                            <p class="slider-content">For Top Deals.</p>
                            <a href="https://t.me/thebest10products" class="btn btn-primary wd-shop-btn slider-btn">
                                Click Here for join<i class="fa fa-arrow-right" aria-hidden="true"></i>
                            </a>
                        </div>
                        <div class="col-sm-6  col-md-6 col-xl-6 order-1 order-sm-2 slider-img">
                            <img src="assets/img/telegram-qr.png" style="width: 50%;" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!-- =========================
        Choose Category
    ============================== -->

    <!--<section id="choose-category">
        <div class="col-md-12 text-center">
            <h2 class="choose-category-main-title">Recently added products</h2>
        </div>
    
    	<div class="container-fluid custom-width" *ngFor="let recentlyAddedProduct of recentlyAddedProducts">
        
    		<div class="choose-category-area-box" *ngIf="recentlyAddedProduct.data && recentlyAddedProduct.data.length>0">
	    		<div class="row">
					<div class="col-md-12">
						<h4 class="choose-category-main-title">{{recentlyAddedProduct.title}}</h4>
					</div>
	    			<div class="col-sm-6 col-md-4 col-lg-3 choose-category-box wow fadeIn animated" data-wow-delay="300ms" *ngFor="let product of recentlyAddedProduct.data">
						<div class="d-flex justify-content-start align-items-center">
		    				<div class="category-info">
								<h6 class="choose-category-title">{{product.brand}}  {{product.id}}</h6>
	    						<div class="choose-category-content">
									<p>{{product.overview}}</p>
	    						</div>
	    						<span style="cursor:pointer"  (click)="gotToProductView(product)" class="badge badge-light choose-category-link">Check best price list</span>
		    				</div>
		    				<div class="category-img">
		    					<img class="assets/img-fluid" src="{{product?.images?.[0]}}" alt="">
		    				</div>
						</div>
						<span class="wd-border-bottom" style="background: #ff8a80"></span>
	    			</div>
	    		</div>
    		</div>
           
            &nbsp;
    	</div>
    </section>-->


    <ng-container>
        <section id="product-amazon" class="style-fd" *ngIf="recentlyAddedProducts">
            <div class="container-fluid custom-width" *ngFor="let recentlyAddedProduct of recentlyAddedProducts;">
                <div class="row" *ngIf="recentlyAddedProduct.data && recentlyAddedProduct.data.length>0">
                    <div class="col-12">
                        <div class="section-title-center text-left">
                            <h2 class="title pl-0">{{recentlyAddedProduct.title}} </h2>
                        </div>
                    </div>
                    <!-- <div class="owl-carousel"> -->
                    <!-- <mat-card   *ngFor="let product of recentlyAddedProduct.data; let i=index;" style="width: 400px;">
                        <mat-card-header>
                          <div mat-card-avatar style=" background-image: url('assets/img/brands/{{product.markets[0].title}}.webp');
                          background-size: cover;"></div>
                          <mat-card-title>{{product.brand}}</mat-card-title>
                          <mat-card-subtitle>{{product.id}}</mat-card-subtitle>
                        </mat-card-header>
                        <img mat-card-image src="{{product?.images?.[0]}}" alt="Photo of a Shiba Inu">
                        <mat-card-content>
                          <p>
                            The Shiba Inu is the smallest of the six original and distinct spitz breeds of dog from Japan.
                            A small, agile dog that copes very well with mountainous terrain, the Shiba Inu was originally
                            bred for hunting.
                          </p>
                        </mat-card-content>
                        <mat-card-actions>
                            <div class="rating">
                                <a href="#"><i
                                    class="fa fa-star{{product.overallRating>=1?' active-color':'-o'}}"
                                    aria-hidden="true"></i></a>
                            <a href="#"><i
                                    class="fa fa-star{{product.overallRating>=2?' active-color':'-o'}}"
                                    aria-hidden="true"></i></a>
                            <a href="#"><i
                                    class="fa fa-star{{product.overallRating>=3?' active-color':'-o'}}"
                                    aria-hidden="true"></i></a>
                            <a href="#"><i
                                    class="fa fa-star{{product.overallRating>=4?' active-color':'-o'}}"
                                    aria-hidden="true"></i></a>
                            <a href="#"><i
                                    class="fa fa-star{{product.overallRating>=5?' active-color':'-o'}}"
                                    aria-hidden="true"></i></a>
                            </div>
                            <div class="compare-btn">
                                <a class="btn btn-primary btn-sm" href="#"><i class="fa fa-exchange" aria-hidden="true"></i> Add to compare</a>
                            </div>
                          <button mat-button>LIKE</button>
                          <button mat-button>SHARE</button>
                        </mat-card-actions>
                      </mat-card>-->
                    <div id="product-slider-two" class="products-by-category owl-carousel owl-theme">
                        <div class="item" *ngFor="let product of recentlyAddedProduct.data; let i=index;">
                            <figure class="figure product-box" >
                                <div class="product-box-img">
                                    <span (click)="gotToProductView(product)">
                                        <img src="{{product?.images?.[0]}}"  class="figure-img img-fluid"
                                            alt="Product Img">
                                    </span>
                                </div>
                                <div class="quick-view-btn">
                                    <div class="compare-btn">
                                        <button type="button" (click)="showProductQuickView(product)"
                                            class="btn btn-primary btn-sm"><i class="fa fa-eye" aria-hidden="true"></i>
                                            Quick view</button>
                                    </div>
                                </div>
                                <figcaption class="figure-caption text-center">
                                    <span class="badge badge-secondary wd-badge text-uppercase">New</span>
                                    <div class="wishlist">
                                        <i class="fa fa-heart" aria-hidden="true"></i>
                                    </div>
                                    <div class="price-start">
                                        <p>Price start from <strong class="active-color"><u>&#x20b9;
                                                    {{product?.markets?.[0].price}}</u> </strong></p>
                                    </div>
                                    <div class="content-excerpt">
                                        <p> {{product.brand}} {{product.id}}</p>
                                    </div>
                                    <div class="rating">
                                        <a href="#"><i
                                                class="fa fa-star{{product.overallRating>=1?' active-color':'-o'}}"
                                                aria-hidden="true"></i></a>
                                        <a href="#"><i
                                                class="fa fa-star{{product.overallRating>=2?' active-color':'-o'}}"
                                                aria-hidden="true"></i></a>
                                        <a href="#"><i
                                                class="fa fa-star{{product.overallRating>=3?' active-color':'-o'}}"
                                                aria-hidden="true"></i></a>
                                        <a href="#"><i
                                                class="fa fa-star{{product.overallRating>=4?' active-color':'-o'}}"
                                                aria-hidden="true"></i></a>
                                        <a href="#"><i
                                                class="fa fa-star{{product.overallRating>=5?' active-color':'-o'}}"
                                                aria-hidden="true"></i></a>
                                    </div>
                                    <div class="compare-btn">
                                        <a class="btn btn-primary btn-sm" href="#"><i
                                                aria-hidden="true"></i> Show More</a>
                                    </div>
                                </figcaption>
                            </figure>
                        </div>
                    </div>


                </div>
            </div>
        </section>

    </ng-container>

</div>


<product-quick-view-modal [product]="selectedProdct"></product-quick-view-modal>