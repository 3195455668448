<section class="product-details" *ngIf="showData">
    <div class="container">
        <div class="row">
            <div class="col-12 p0">
                <div class="page-location">
                    <ul>
                        <li><a href="#">
                                Home <span class="divider">/</span>
                            </a></li>
                        <li><span class="page-location-active">
                                {{product.brand}} {{product.id}}
                                <span class="divider">/</span>
                            </span></li>
                    </ul>
                </div>
            </div>
            <div class="col-12 product-details-section">
                <!-- ====================================
                    Product Details Gallery Section
                ========================================= -->
                <div class="row">
                    <div class="product-gallery col-12 col-md-12 col-lg-6">
                        <!-- ====================================
                            Single Product Gallery Section
                        ========================================= -->
                        <div class="row">
                            <div class="col-md-12 product-slier-details" [innerHTML]="lightSlider | safeHtml">

                            </div>
                        </div>
                    </div>
                    <div class="col-6 col-12 col-md-12 col-lg-6">
                        <div class="product-details-gallery">
                            <div class="list-group">
                                <h4 class="list-group-item-heading product-title">
                                    {{product.brand}} {{product.id}}
                                </h4>
                                <div class="media">
                                    <div class="media-left media-middle">
                                        <div class="rating">
                                            <a href="#"><i
                                                    class="fa fa-star{{product.overallRating>=1?' active-color':'-o'}}"
                                                    aria-hidden="true"></i></a>
                                            <a href="#"><i
                                                    class="fa fa-star{{product.overallRating>=2?' active-color':'-o'}}"
                                                    aria-hidden="true"></i></a>
                                            <a href="#"><i
                                                    class="fa fa-star{{product.overallRating>=3?' active-color':'-o'}}"
                                                    aria-hidden="true"></i></a>
                                            <a href="#"><i
                                                    class="fa fa-star{{product.overallRating>=4?' active-color':'-o'}}"
                                                    aria-hidden="true"></i></a>
                                            <a href="#"><i
                                                    class="fa fa-star{{product.overallRating>=5?' active-color':'-o'}}"
                                                    aria-hidden="true"></i></a>
                                        </div>
                                    </div>
                                    <div class="media-body">
                                        <p>{{product.overallRating}}/5 <span class="product-ratings-text">
                                                -{{product.totalNoOfRatings?product.totalNoOfRatings:17233}}
                                                Ratings</span></p>
                                    </div>
                                </div>
                            </div>
                            <div class="list-group content-list">
                                <p><i class="fa fa-dot-circle-o" aria-hidden="true"></i> 100% Original product</p>
                                <p><i class="fa fa-dot-circle-o" aria-hidden="true"></i> Manufacturer Warranty</p>
                            </div>
                        </div>
                        <div class="product-store row">
                            <div class="col-12 product-store-box" *ngFor="let market of product.markets">
                                <div class="row">
                                    <div class="col-3 p0 store-border-img">
                                        <img src="assets/img/brands/{{market.title}}.webp" style="padding-top: 17px;" class="figure-img img-fluid"
                                            alt="Product Img">
                                    </div>
                                    <div class="col-5 store-border-price text-center">
                                        <div class="price">
                                            <p>&#x20b9; {{market?.price}}</p>
                                        </div>
                                    </div>
                                    <div class="col-4 store-border-button" style="padding-bottom:10px">
                                        <a href="{{market.link}}" target="_blank"
                                            class="btn btn-primary wd-shop-btn pull-right">
                                            Buy it now
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="wd-tab-section">
                    <div class="bd-example bd-example-tabs">
                        <ul class="nav nav-pills mb-3 wd-tab-menu" id="pills-tab" role="tablist">
                            <li class="nav-item col-6 col-md">
                                <a class="nav-link active" id="description-tab" data-toggle="pill"
                                    href="#description-section" role="tab" aria-controls="description-section"
                                    aria-expanded="true">Description</a>
                            </li>
                            <li class="nav-item col-6 col-md">
                                <a class="nav-link" id="full-specifiction-tab" data-toggle="pill"
                                    href="#full-specifiction" role="tab" aria-controls="full-specifiction"
                                    aria-expanded="false">Ful Specifiction</a>
                            </li>
                        </ul>
                        <div class="tab-content" id="pills-tabContent">
                            <div class="tab-pane fade active show " id="description-section" role="tabpanel"
                                aria-labelledby="description-tab" aria-expanded="true">
                                <div class="product-tab-content">
                                    <h4 class="description-title"> {{product.brand}} {{product.id}}</h4>

                                    <h6 class="description-subtitle">Overview</h6>
                                    <p class="description-subcontent">{{product.overview}}</p>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="full-specifiction">

                                <p class="wd-opacity"></p>

                                <ul class="list-group wd-info-section">
                                    <li class="list-group-item d-flex justify-content-between align-items-center p0"
                                        *ngFor="let index of specKeys; let i = index; let even = even">
                                        <div class="col-12 col-md-6 info-section" *ngIf="even">
                                            <p>{{specKeys[i]}} : {{product.spec[specKeys[i]]}}</p>
                                        </div>
                                        <div class="col-12 col-md-5 info-section" *ngIf="even">
                                            <p>{{specKeys[i+1]}} : {{product.spec[specKeys[i+1]]}}</p>
                                        </div>
                                        <div class="col-1" *ngIf="even"></div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>