import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit,AfterViewInit {
  
  constructor(public readonly location: Location,
      public fb: FormBuilder,
      public dialog: MatDialog,
      public router: Router,
      public cdr: ChangeDetectorRef,
      public route: ActivatedRoute) {

  }
  ngAfterViewInit(): void {
   
  }

  ngOnInit() {
     // this.recentlyAddedProducts = this.theB10ProductsService.getRecentlyAddedProducts();
    
  }

 
}
