import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TheB10ProductsService } from 'src/app/services/theb10.service';
declare var $:any;

@Component({
    templateUrl: './blogs.component.html'
})
export class BlogsComponent implements OnInit,AfterViewInit {
    blogsData:any
    currentPageData:any
    activePageIndex=1;
    selectedBreadcrumb="All"
    tags:any
    constructor(public readonly location: Location,
        public fb: FormBuilder,
        public dialog: MatDialog,
        public router: Router,
        public cdr: ChangeDetectorRef,
        public route: ActivatedRoute,
        public theB10ProductsService: TheB10ProductsService,) {
        this.blogsData = theB10ProductsService.getBlogsForPagination(undefined,undefined,undefined,undefined)
        this.currentPageData = this.blogsData[0];
        this.tags = theB10ProductsService.getBlogCategories()
    }

    ngOnInit() {
          
    }

    ngAfterViewInit(): void {
       
    }

    previousPage(){
        if(this.activePageIndex==1){
            return;
        }
        --this.activePageIndex
        this.currentPageData = this.blogsData[this.activePageIndex-1]
        document.getElementById("blog-section").scrollIntoView();
    }
    
    nextPage(){
        if(this.activePageIndex==this.blogsData.length){
            return;
        }
        ++this.activePageIndex
        this.currentPageData = this.blogsData[this.activePageIndex-1]
        document.getElementById("blog-section").scrollIntoView();
    }
    gotoFirstPage(){
        if(this.activePageIndex==1){
            return;
        }
        this.activePageIndex = 1
        this.currentPageData = this.blogsData[this.activePageIndex-1]
        document.getElementById("blog-section").scrollIntoView();
    }

    gotoLastPage(){
        if(this.activePageIndex==this.blogsData.length){
            return;
        }
        this.activePageIndex = this.blogsData.length
        this.currentPageData = this.blogsData[this.activePageIndex-1]
        document.getElementById("blog-section").scrollIntoView();
    }

    showPageData(i:number,blogData:any){
        this.activePageIndex=i+1;
        this.currentPageData=blogData;
        document.getElementById("blog-section").scrollIntoView();
    }
}
