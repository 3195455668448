import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { BreadcrumbModule } from './breadcrumb/breabcrumb.module';
import { MaterialModule } from './material.module';
import { AppRoutingModule } from './routes';
import { TheB10ProductsService } from './services/theb10.service';
import { FullLayoutComponent } from './views/layout';
import { ViewesModule } from './views/views.module';



@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BreadcrumbModule,
    MaterialModule,
    ViewesModule
  ],
  providers: [TheB10ProductsService],
  bootstrap: [AppComponent]
})
export class AppModule { }
