import { Component, OnInit, Input, Inject, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
declare var $:any;

@Component({
    selector:"product-quick-view-modal",
	templateUrl: './product-quick-view.model.componet.html',
	providers: []
})
export class ProductDetailsQuickViewComponent implements OnInit,AfterViewInit {
	@Input() product: any;
	constructor(public route: ActivatedRoute, public router: Router) {
        //this.product = data.product
	}
	ngAfterViewInit(): void {
		
	}
	
	cancel() {
	//	this.dialogRef.close();
	}
	

	ngOnInit() {
	
	}
}
