<section class="blog-slider-tow-grid wd-slider-section">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="text-center">
                    <h1 class="wishlist-slider-title">Blog</h1>
                    <div class="page-location pt-0" id="blog-section">
                        <ul>
                            <li><a href="javascript:void()">
                                    Home <span class="divider">/</span>
                                </a></li>
                            <li><a class="page-location-active" href="javascript:void()">
                                    <span class="active-color">{{selectedBreadcrumb}}</span>
                                    <span class="divider">/</span>
                                </a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- =========================
    Blog Section
============================== -->
<section class="blog-section">
    <div class="container">
        <div class="row four-grid">
            <div class="col-md-4 col-lg-3 pl-0">
                <!-- =========================
                    Search Option
                ============================== -->
                <div class="sidebar-search">
                    <div class="input-group wd-btn-group col-12 p0">
                        <input type="text" class="form-control" placeholder="Search ..." aria-label="Search for...">
                        <span class="input-group-btn">
                            <button class="btn btn-secondary wd-btn-search" type="button">
                                <i class="fa fa-search" aria-hidden="true"></i>
                            </button>
                        </span>
                    </div>
                </div>

                <!-- =========================
                    Category Option
                ============================== -->
                <button type="button" class="btn btn-info collapse-category collapsed" data-toggle="collapse"
                    data-target="#collapse-category">
                    Our Category <i class="fa fa-bars" aria-hidden="true"></i>
                </button>
                <div id="collapse-category" class="collapse">
                    <div class="side-bar category category-sm">
                        <ul class="dropdown-list-menu">
                            <li>
                                <a href="javascript:void()"><i class="fa fa-angle-double-right" aria-hidden="true"></i>
                                    Mobiles</a>
                            </li>
                            <li>
                                <a href="javascript:void()"><i class="fa fa-angle-double-right" aria-hidden="true"></i>
                                    Tablet</a>
                            </li>
                            <li>
                                <a href="javascript:void()"><i class="fa fa-angle-double-right" aria-hidden="true"></i>
                                    Accessories</a>
                            </li>
                            <li>
                                <a href="javascript:void()"><i class="fa fa-angle-double-right" aria-hidden="true"></i>
                                    Visual</a>
                            </li>
                            <li class="sidebar-dropdown">
                                <p><i class="fa fa-angle-double-right" aria-hidden="true"></i> Handset </p>
                                <ul class="dropdown-sub-menu">
                                    <li><a href="javascript:void()"><i class="fa fa-angle-right" aria-hidden="true"></i>
                                            Touch</a></li>
                                    <li><a href="javascript:void()"><i class="fa fa-angle-right" aria-hidden="true"></i>
                                            Button</a></li>
                                    <li><a href="javascript:void()"><i class="fa fa-angle-right" aria-hidden="true"></i>
                                            Speaker</a></li>
                                    <li><a href="javascript:void()"><i class="fa fa-angle-right" aria-hidden="true"></i>
                                            Protector</a></li>
                                </ul>
                            </li>
                            <li>
                                <a href="javascript:void()"><i class="fa fa-angle-double-right" aria-hidden="true"></i>
                                    Mini-pad</a>
                            </li>
                            <li>
                                <a href="javascript:void()"><i class="fa fa-angle-double-right" aria-hidden="true"></i>
                                    Headphones</a>
                            </li>
                            <li>
                                <a href="javascript:void()"><i class="fa fa-angle-double-right" aria-hidden="true"></i>
                                    Camera</a>
                            </li>
                            <li>
                                <a href="javascript:void()"><i class="fa fa-angle-double-right" aria-hidden="true"></i>
                                    Lens</a>
                            </li>
                            <li>
                                <a href="javascript:void()"><i class="fa fa-angle-double-right" aria-hidden="true"></i>
                                    Laptop</a>
                            </li>
                            <li>
                                <a href="javascript:void()"><i class="fa fa-angle-double-right" aria-hidden="true"></i>
                                    Desktop</a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="side-bar category category-md">
                    <h5 class="title">Category</h5>
                    <ul class="dropdown-list-menu">
                        <li>
                            <a href="javascript:void()"><i class="fa fa-angle-double-right" aria-hidden="true"></i>
                                Mobile</a>
                        </li>
                        <li>
                            <a href="javascript:void()"><i class="fa fa-angle-double-right" aria-hidden="true"></i>
                                Tablet</a>
                        </li>
                        <li>
                            <a href="javascript:void()"><i class="fa fa-angle-double-right" aria-hidden="true"></i>
                                Accessories</a>
                        </li>
                        <li>
                            <a href="javascript:void()"><i class="fa fa-angle-double-right" aria-hidden="true"></i>
                                Visual</a>
                        </li>
                        <li class="sidebar-dropdown">
                            <p><i class="fa fa-angle-double-right" aria-hidden="true"></i> Handset </p>
                            <ul class="dropdown-sub-menu">
                                <li><a href="javascript:void()"><i class="fa fa-angle-right" aria-hidden="true"></i>
                                        Touch</a></li>
                                <li><a href="javascript:void()"><i class="fa fa-angle-right" aria-hidden="true"></i>
                                        Button</a></li>
                                <li><a href="javascript:void()"><i class="fa fa-angle-right" aria-hidden="true"></i>
                                        Speaker</a></li>
                                <li><a href="javascript:void()"><i class="fa fa-angle-right" aria-hidden="true"></i>
                                        Protector</a></li>
                            </ul>
                        </li>
                        <li>
                            <a href="javascript:void()"><i class="fa fa-angle-double-right" aria-hidden="true"></i>
                                Mini-pad</a>
                        </li>
                        <li>
                            <a href="javascript:void()"><i class="fa fa-angle-double-right" aria-hidden="true"></i>
                                Headphones</a>
                        </li>
                        <li>
                            <a href="javascript:void()"><i class="fa fa-angle-double-right" aria-hidden="true"></i>
                                Camera</a>
                        </li>
                        <li>
                            <a href="javascript:void()"><i class="fa fa-angle-double-right" aria-hidden="true"></i>
                                Lens</a>
                        </li>
                        <li>
                            <a href="javascript:void()"><i class="fa fa-angle-double-right" aria-hidden="true"></i>
                                Laptop</a>
                        </li>
                        <li>
                            <a href="javascript:void()"><i class="fa fa-angle-double-right" aria-hidden="true"></i>
                                Desktop</a>
                        </li>
                    </ul>
                </div>

                <!-- =========================
                    Share Option
                ============================== -->
                <div class="side-bar sheare-option">
                    <h6 class="sheare-title">Share</h6>
                    <a href="javascript:void()">
                        <div class="sheare-box share-facebook">
                            <i class="fa fa-facebook" aria-hidden="true"></i>
                        </div>
                    </a>
                    <a href="javascript:void()">
                        <div class="sheare-box share-twitter">
                            <i class="fa fa-twitter" aria-hidden="true"></i>
                        </div>
                    </a>
                    <a href="javascript:void()">
                        <div class="sheare-box share-pinterest">
                            <i class="fa fa-pinterest-p" aria-hidden="true"></i>
                        </div>
                    </a>
                    <a href="javascript:void()">
                        <div class="sheare-box share-behance">
                            <i class="fa fa-behance" aria-hidden="true"></i>
                        </div>
                    </a>
                    <a href="javascript:void()">
                        <div class="sheare-box share-skype">
                            <i class="fa fa-skype" aria-hidden="true"></i>
                        </div>
                    </a>
                    <a href="javascript:void()">
                        <div class="sheare-box share-instagram">
                            <i class="fa fa-instagram" aria-hidden="true"></i>
                        </div>
                    </a>
                    <a href="javascript:void()">
                        <div class="sheare-box share-linkedin">
                            <i class="fa fa-linkedin" aria-hidden="true"></i>
                        </div>
                    </a>
                    <a href="javascript:void()">
                        <div class="sheare-box share-google">
                            <i class="fa fa-google-plus" aria-hidden="true"></i>
                        </div>
                    </a>
                </div>
                <!-- =========================
                    Archive Option
                ============================== -->
                <div class="side-bar archive-option">
                    <h6 class="archive-review-title">Archive</h6>
                    <p><a href="javascript:void()" >2021 <i class="fa fa-angle-double-right" aria-hidden="true"></i></a>
                    </p>
                    <p><a href="javascript:void()">2022 <i class="fa fa-angle-double-right" aria-hidden="true"></i></a>
                    </p>
                    <p><a href="javascript:void()">2023<i class="fa fa-angle-double-right" aria-hidden="true"></i></a>
                    </p>
                </div>

                <!-- =========================
                    Tags Box Option
                ============================== -->
                <div class="side-bar tags-box">
                    <h5 class="title">Tags</h5>
                    <ul>
                       <ngtemplate *ngFor="let tag of tags">
                         <li (click)="showByCategory(tag)"><a href="javascript:void()">{{tag}} </a></li>
                       </ngtemplate>
                    </ul>
                </div>


            </div>
            <div class="col-md-8 col-lg-9">
                <div class="row three-grid">

                    <div class="col-sm-6 col-lg-4" *ngFor="let blogData of currentPageData">
                        <div class="blog-box">
                            <div class="blog-date text-center">
                                <h2 class="date">{{blogData.pDate}}</h2>
                                <h4 class="monthe">{{blogData.pMonth}}</h4>
                            </div>
                            <div class="blog-img">
                                <img [src]="blogData.image" class="figure-img img-fluid" alt="blog-img">
                            </div>
                            <div class="blog-content-box">
                                <a href="{{blogData.link}}">
                                    <h4 class="blog-title">{{blogData.title}}</h4>
                                </a>
                                <p class="blog-content">{{blogData.overview}}</p>
                                <div class="raed-more">
                                    <a class="btn btn-link" href="{{blogData.link}}">
                                        READ MORE <i class="fa fa-angle-right" aria-hidden="true"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="float-right">
                    <nav class="wd-pagination">
                        <ul class="pagination">
                            <li class="page-item {{activePageIndex==1?'disabled':''}}" (click)="gotoFirstPage()"><a
                                    class="page-link" href="javascript:void()">First</a></li>
                            <li class="page-item {{activePageIndex==1?'disabled':''}}" (click)="previousPage()">
                                <a class="page-link" href="javascript:void()" tabindex="-1"><i
                                        class="fa fa-angle-double-left" aria-hidden="true"></i></a>
                            </li>
                            <ngtemplate *ngFor="let blogData of blogsData;let i=index;">
                                <li class="page-item {{i+1==activePageIndex?'active':''}}"
                                    (click)="showPageData(i,blogData)"><a class="page-link"
                                        href="javascript:void()">{{i+1}}</a></li>
                            </ngtemplate>
                            <li class="page-item  {{activePageIndex==blogsData.length?'disabled':''}}"
                                (click)="nextPage()">
                                <a class="page-link" href="javascript:void()" tabindex="-1"><i
                                        class="fa fa-angle-double-right" aria-hidden="true"></i></a>
                            </li>
                            <li class="page-item  {{activePageIndex==blogsData.length?'disabled':''}}"
                                (click)="gotoLastPage()"><a class="page-link" href="javascript:void()">Last</a></li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</section>