import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { Location } from '@angular/common';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TheB10ProductsService } from 'src/app/services/theb10.service';
import { ProductDetailsQuickViewComponent } from '../product/product-quick-view.model.componet';

declare var $: any

@Component({
    selector: 'home',
    templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit, AfterViewInit {

    recentlyAddedProducts: any = {};
    selectedProdct: any
    constructor(public readonly location: Location,
        public fb: FormBuilder,
        public dialog: MatDialog,
        public router: Router,
        public cdr: ChangeDetectorRef,
        public route: ActivatedRoute,
        public theB10ProductsService: TheB10ProductsService,) {

    }

    ngOnInit() {
        this.recentlyAddedProducts = this.theB10ProductsService.getRecentlyAddedProducts();
    }

    ngAfterViewInit() {
        $('.products-by-category').owlCarousel({
            loop: false,
            nav: true,
            navText: ['<i class=\'fa fa-angle-left\'></i>', '<i class=\'fa fa-angle-right\'></i>'],
            dots: false,
            responsive: {
                0: {
                    items: 1
                },
                576: {
                    items: 2
                },
                768: {
                    items: 3
                },
                1200: {
                    items: 4
                }
                ,
                1550: {
                    items: 5
                }
            }
        })

        $('#main-slider').owlCarousel({
            loop: true,
            nav: true,
            items: 1,
            navText: ['<i class=\'fa fa-angle-left\'></i>', '<i class=\'fa fa-angle-right\'></i>'],
            dots: false
        })

        $('#main-slider').on('translate.owl.carousel', function () {
            $('.slider-text').removeClass('fadeInUp animated').hide();
        });
        $('#main-slider').on('translated.owl.carousel', function () {
            $('.slider-text').addClass('fadeInUp animated').show();
        });

        $('#main-slider').on('translate.owl.carousel', function () {
            $('.slider-img').removeClass('fadeInDown animated').hide();
        });
        $('#main-slider').on('translated.owl.carousel', function () {
            $('.slider-img').addClass('fadeInDown animated').show();
        });

        $('#main-slider').on('translate.owl.carousel', function () {
            $('.slider-img-two').removeClass('fadeInDown animated').hide();
        });
        $('#main-slider').on('translated.owl.carousel', function () {
            $('.slider-img-two').addClass('fadeInDown animated').show();
        });

        $('#main-slider').on('translate.owl.carousel', function () {
            $('.slider-countdown').removeClass('fadeInUp animated').hide();
        });
        $('#main-slider').on('translated.owl.carousel', function () {
            $('.slider-countdown').addClass('fadeInUp animated').show();
        });

        $('#main-slider').on('translate.owl.carousel', function () {
            $('.cou-slider-img').removeClass('fadeInDown animated').hide();
        });
        $('#main-slider').on('translated.owl.carousel', function () {
            $('.cou-slider-img').addClass('fadeInDown animated').show();
        });
        window.scrollTo(0,10)
    }

    gotToProductView(product: any) {
        this.router.navigate(['/product'], { queryParams: { category: product.category, id: product.id } })
        //.then(() => {
        //   window.location.reload();
        // })
        // window.open(window.location.protocol + "//" + window.location.host + `/product?category=${product.category}&id=${product.id}`, "_blank");
    }

    showProductQuickView(product: any) {
        this.selectedProdct = product;
        $('#product-quick-view-modal').appendTo("body").modal('show');
    }
}
