import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash';
export interface BreadCrumb {
  label: string;
  url: string;
};


@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.html',
    encapsulation: ViewEncapsulation.None
})
export class BreadcrumbComponent implements OnInit {

    breadcrumbs$:any = [];
    pageTitle$:string | undefined;
    pageHelpText$:string | undefined;
    data:any = {};

    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,public dialogModel:MatDialog) {
        this.breadcrumbs$ = this.buildBreadCrumb(this.activatedRoute.root);
    }

    ngOnInit() {
        this.router.events.subscribe((evt) => {
            if(!(evt instanceof NavigationEnd)) {
                return;
            }
            this.breadcrumbs$ = this.buildBreadCrumb(this.activatedRoute.root)
        });
        // Build your breadcrumb starting with the root route of your current activated route

    }

   

    buildBreadCrumb(route: ActivatedRoute, url: string = '',
                    breadcrumbs: Array<BreadCrumb> = []):any{
        // If no routeConfig is avalailable we are on the root path
        if(route){
        const label = route.routeConfig ? route.routeConfig.data[ 'breadcrumb' ] : '';
        this.pageTitle$ = route.routeConfig ? route.routeConfig.data[ 'title' ] : '';
        this.pageHelpText$ = route.routeConfig ? route.routeConfig.data['helpText'] : '';
        const path = route.routeConfig ? route.routeConfig.path : '';
        var queryParams;
        const paramsData = route.queryParams.subscribe(params => {
            if(typeof params == 'object')
            queryParams = params;
        });
        // In the routeConfig the complete path is not available,
        // so we rebuild it each time
        const nextUrl = `${url}${path}/`;

        const breadcrumb = {
            label: label,
            url: nextUrl
        };
        const newBreadcrumbs = [ ...breadcrumbs, breadcrumb ];
        if (route.firstChild) {
            // If we are not on our current path yet,
            // there will be more children to look after, to build our breadcumb
            return this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs);
        }
        var breadCrumbs:any = [];
        _.forEach(newBreadcrumbs, function(item:any,index){
            if(item.label == undefined || item.label == ''){
            } else {
                item.queryParams = {};
                breadCrumbs.push(item);
            }
        });
        if(queryParams != undefined){
            breadCrumbs = this.prepareUrl(queryParams,breadCrumbs);
        }
        return breadCrumbs;
      }
    }

    prepareUrl(queryParams: undefined,breadCrumbs: any[]){
        var prepareUrlData = breadCrumbs[breadCrumbs.length-1];
        breadCrumbs[breadCrumbs.length-1].queryParams = queryParams;
        return breadCrumbs;
    }
}
